import React from "react"
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import { Link } from "gatsby"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ActivityTypeMark } from "styles/GlobalStyles.js"
import { CustomContainer } from "styles/CustomContainer.styles.js"

import FeatureImage from "components/FeatureImage"

import PhotographThumbnails from "components/PhotographThumbnails"

const ActivityTeaserInDepth = ({step, acti}) => {
    var stepText = "Activity #" + step + ":"
    console.log('activity (#sdfsdfsdF): ', acti)

    const featuredPhoto = acti.relationships.field_activity_feature_photo
    
    return (
        <CustomContainer>
            <Row>
                <Col className="col" xs={12} md={12}>
                    <ActivityTypeMark>{stepText}</ActivityTypeMark>
                    <h2>
                        <Link to={acti.fields.slug}>
                            {acti.title}
                        </Link>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col className="col" xs={12} md={12}>
                    <FeatureImage featureImage={featuredPhoto} aspectRatio='1x1' />
                </Col>
            </Row>
            <Row>
                <Col className="col" xs={12} md={12}>
                    {/* <ActivityDetailsTable acti={acti} detailLevel={"teaser"} layout={"compact"} /> */}
                    <PhotographThumbnails acti={acti} activityNo={step} detailLevel={"teaser"} layout={"compact"} />
                </Col>
            </Row>
        </CustomContainer>
    )
}

export default ActivityTeaserInDepth
import React from 'react';
import { Link } from "gatsby"

import { PhotographThumbnailsContainer } from "./styles.js"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PhotographTypeMark } from "styles/GlobalStyles.js"

import FeatureImage from 'components/FeatureImage'

// used on "View Photos(21) page"
const PhotographThumbnails = ({acti, activityNo}) => {
    console.log('in PhotographThumbnails (#40fginfbc). Activity:', acti)
    // renders the button: "View Activity photos (5)"
    const photographs = acti.relationships.field_step_photographs
    console.log('photographs (#fncw9wu4wf): ', photographs)
    
    // todo: use this or delete it:
    // const viewActivityButton = ({ acti }) => {
    //     const photographs = acti.relationships.field_step_photographs
    //     console.log('Activity photographs (#wsvsd)', photographs)
    //     const photographCount = photographs.length
    //     if (photographCount > 1) {
    //         return (
    //             <Link to={acti.fields.slug}>
    //                 <Button size="sm" className="actiPlan">
    //                     View Activity photos ({photographCount})
    //                 </Button>
    //             </Link>
    //         )
    //     } else {
    //         return (
    //             <Button size="sm" className="disabled btn-secondary" Style="cursor: default">
    //                 View Activity photos (empty)
    //             </Button>
    //         )
    //         // return '[no activity plan]'
    //     }
    // }
    
    return (
        <PhotographThumbnailsContainer>
            <Row>
                {photographs.map((photo, index) => {
                    const step = index+1
                    console.log ('current photo: ', photo)
                    console.log ('counter: ', step)

                    return (
                    <Col className="" xs={6} md={6} lg={4}>
                        <PhotographTypeMark>Photo #{activityNo}.{step}</PhotographTypeMark>
                        <Link to={acti.fields.slug+'#photo_' + step}>
                            <FeatureImage featureImage={photo} aspectRatio='1x1' />
                        </Link>
                    </Col>
                    )
                })}
            </Row>
        </PhotographThumbnailsContainer>
        
    )
};

export default PhotographThumbnails;
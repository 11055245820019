import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"

import ActivityTeaserInDepth from 'components/ActivityTeaserInDepth'
import ItinDetailsTable from 'components/ItinDetailsTable'
import DescriptionBox from 'components/DescriptionBox'
import SaveButtonContainer from "../components/SavedItems/SaveButtonContainer"

// this is the page for the: "View Photographs (21)" button
const IndexPage = ({ data, pageContext }) => {
  const itin            = data.allNodeItinerary.edges[0].node
  const title           = itin.title
  const activities      = itin.relationships.field_itinerary_activities
  
  if (itin.body) {
    var itinDesc = itin.body.value
  }

  return (
    <Layout>
      <SEO title={title} />
      <main>

        <SaveButtonContainer node={itin}>
          <ItinDetailsTable itin={itin} detailLevel={"detailed"} layout={"large"} viewMode={"photographs"} headerText={title} />
        </SaveButtonContainer>
        
        <DescriptionBox title={"Description"} html={itinDesc} />

        {activities.map((acti, index) => {
          // console.log ('current activity: ', acti)
          // console.log ('counter: ', index)

          return (
            <div key={acti.id}>
              <ActivityTeaserInDepth step={index+1} acti={acti} />
              
            </div>
          )
        })}

      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query FindItineraryByNIdInDepth($nid: Int) {
  allNodeItinerary(filter: {drupal_internal__nid: {eq: $nid}}) {
    edges {
      node {
        id
        drupal_id
        __typename
        drupal_internal__nid
        fields {
          slug
          slug_highlights
          slug_activities
        }
        path {
          alias
        }
        title
        body {
          value
          processed
        }
        field_activity_length
        field_distance_in_time_
        field_itin_transport_mode
        relationships {
          field_starting_location {
            title
            fields {
              slug
            }
            relationships {
              field_state {
                title
                relationships {
                  field_states_country {
                    title
                    drupal_id
                  }
                }
                drupal_id
              }
            }
          }
          field_variant_itineraries {
            id
            drupal_internal__nid
            title
            fields {
              slug
              slug_highlights
            }
          }
          field_itin_featured_locations {
            ... on node__photograph {
              drupal_internal__nid
            }
            ... on node__location {
              drupal_internal__nid
            }
          }
          field_itin_feature_image {
            drupal_id
            relationships {
              field_photo_images {
                drupal_id
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200, maxHeight: 600, cropFocus: CENTER
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          field_itinerary_activities {
            id
            title
            body {
              processed
            }
            fields {
              slug
            }
            field_activity_distance_in_km
            drupal_internal__nid
            field_is_activity_plan_available
            field_physical_difficulty
            field_activity_return_type
            field_recommended_time_in_situ
            field_terrain_description
            field_total_time_recommended
            field_travel_time_one_way
            relationships {
              field_step_photographs {
                drupal_internal__nid
                id
                fields {
                  slug
                }
                title
                field_photo_photographer {
                  title
                  uri
                }
                body {
                  processed
                }
                field_is_4x3_available
                relationships {
                  field_photo_images {
                    id
                    drupal_internal__fid
                    localFile {
                      childImageSharp {
                        id
                        fluid(
                          maxWidth: 700, maxHeight: 700, cropFocus: CENTER
                        ) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  field_photographer {
                    drupal_internal__nid
                    id
                    title
                    fields {
                      slug
                    }
                  }
                }
              }

              field_activity_feature_photo {
                id
                relationships {
                  field_photo_images {
                    drupal_id
                    localFile {
                      childImageSharp {
                        fluid(
                          maxWidth: 1200, maxHeight: 600, cropFocus: CENTER
                        ) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`